export const ProjectBlurbs = {
'internet-pressure' : `<p>Lead</p> 
                        <p>Simon Angus</p>
                        <br/>
                        <p>Overview</p>
                        <br/>
                        <p>We are developing a digital model of Melbourne that maps the 
                        evolving impacts of COVID-19 on different places and communities
                        of the city. 
                        We are developing a digital model of Melbourne that maps the 
                        evolving impacts of COVID-19 on different places and communities
                        of the city. 
                        We are developing a digital model of Melbourne that maps the 
                        evolving impacts of COVID-19 on different places and communities
                        of the city. 
                        We are developing a digital model of Melbourne that maps the 
                        evolving impacts of COVID-19 on different places and communities
                        of the city. 
                        We are developing a digital model of Melbourne that maps the 
                        evolving impacts of COVID-19 on different places and communities
                        of the city. 
                        We are developing a digital model of Melbourne that maps the 
                        evolving impacts of COVID-19 on different places and communities
                        of the city. 
                        We are developing a digital model of Melbourne that maps the 
                        evolving impacts of COVID-19 on different places and communities
                        of the city. 
                        We are developing a digital model of Melbourne that maps the 
                        evolving impacts of COVID-19 on different places and communities
                        of the city. 
                        We are developing a digital model of Melbourne that maps the 
                        evolving impacts of COVID-19 on different places and communities
                        of the city. 
                        </p>
                        <br/>
                        <p>Explore</p>
                        <br/>
                        <p>We found that the socio-economic diversity between inner and outer
                            Melbourne vastly impacted the effects of COVID-19 on outcomes ..
                        </p>
                        <br/>
                        <p>Research</p>
                        <br/>
                        <ul>
                            <li>Grodach, et al. (2020), Title of the work, J. Urban Econ.</li>
                            <li>Grodach, et al. (2020), Title of the work, J. Urban Econ.</li>
                        </ul>`,
    
'employment-vulnerability' : `<p>Lead</p> 
                    <p>Carl Grodach</p>
                    <br/>
                    <p>Overview</p>
                    <br/>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris 
                    nisi ut aliquip ex ea commodo consequat.. </p>
                    <br/>
                    <p>Explore</p>
                    <br/>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                    <br/>
                    <p>Research</p>
                    <br/>
                    <ul>
                        <li>Abc, et al. (2020), Title of the work, J. Urban Econ.</li>
                        <li>Pqr, et al. (2020), Title of the work, J. Urban Econ.</li>
                    </ul>`,

'psychological' : `<p>Lead</p> 
                    <p>John Doe</p>
                    <br/>
                    <p>Overview</p>
                    <br/>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris 
                    nisi ut aliquip ex ea commodo consequat.. </p>
                    <br/>
                    <p>Explore</p>
                    <br/>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                    <br/>
                    <p>Research</p>
                    <br/>
                    <ul>
                        <li>Abc, et al. (2020), Title of the work, J. Urban Econ.</li>
                        <li>Pqr, et al. (2020), Title of the work, J. Urban Econ.</li>
                    </ul>`,
'digital' : `<p>Lead</p> 
                    <p>John Doe</p>
                    <br/>
                    <p>Overview</p>
                    <br/>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris 
                    nisi ut aliquip ex ea commodo consequat.. </p>
                    <br/>
                    <p>Explore</p>
                    <br/>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                    <br/>
                    <p>Research</p>
                    <br/>
                    <ul>
                        <li>Abc, et al. (2020), Title of the work, J. Urban Econ.</li>
                        <li>Pqr, et al. (2020), Title of the work, J. Urban Econ.</li>
                    </ul>`,
'health' : `<p>Lead</p> 
                    <p>John Doe</p>
                    <br/>
                    <p>Overview</p>
                    <br/>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris 
                    nisi ut aliquip ex ea commodo consequat.. </p>
                    <br/>
                    <p>Explore</p>
                    <br/>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                    <br/>
                    <p>Research</p>
                    <br/>
                    <ul>
                        <li>Abc, et al. (2020), Title of the work, J. Urban Econ.</li>
                        <li>Pqr, et al. (2020), Title of the work, J. Urban Econ.</li>
                    </ul>`,
    
    
                        
}


      {/* <Nav id='3'>
          <NavItem title={"Psychological Impacts"} />
          <SubNavItem>
          <div dangerouslySetInnerHTML={{ __html: ProjectBlurbs['psychological'] }} />
              </SubNavItem>
        </Nav>
        <Nav id='4'>
          <NavItem title={"Digital Melbourne"} />
          <SubNavItem>
          <div dangerouslySetInnerHTML={{ __html: ProjectBlurbs['digital'] }} />
              </SubNavItem>
        </Nav>
        <Nav id='5'>
          <NavItem title={"Health Services Use"} />
          <SubNavItem>
          <div dangerouslySetInnerHTML={{ __html: ProjectBlurbs['health'] }} />
              </SubNavItem>
        </Nav>
        <Nav id='6'>
          <NavItem title={"Sleep Changes"} />
          <SubNavItem>
          <div dangerouslySetInnerHTML={{ __html: ProjectDesc }} />
              </SubNavItem>
        </Nav>
        <Nav id='7'>
          <NavItem title={"Medical Care"} />
          <SubNavItem>
          <div dangerouslySetInnerHTML={{ __html: ProjectDesc }} />
              </SubNavItem>
        </Nav>
        <Nav id='8'>
          <NavItem title={"City-Wide Survey"} />
          <SubNavItem>
          <div dangerouslySetInnerHTML={{ __html: ProjectDesc }} />
              </SubNavItem>
        </Nav>
        <Nav id='9'>
          <NavItem title={"Violence Against Women"} />
          <SubNavItem>
          <div dangerouslySetInnerHTML={{ __html: ProjectDesc }} />
              </SubNavItem>
        </Nav>
        <Nav id='10'>
          <NavItem title={"Water Infrastructure"} />
          <SubNavItem>
          <div dangerouslySetInnerHTML={{ __html: ProjectDesc }} />
              </SubNavItem>
        </Nav> */}