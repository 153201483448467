// import * as React from "react";
import React, { Component, useState } from "react";
import styled from "styled-components";
import { SideNav, Nav, NavContext } from "react-sidenav";
import { ProjectBlurbs } from "../../constants/projects";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faCross, faWifi, faBriefcase } from '@fortawesome/free-solid-svg-icons'
// docs: https://react-sidenav.now.sh/

// https://github.com/wmira/react-sidenav
// using React hooks here: https://reactjs.org/docs/hooks-intro.html
// export const SideMenu = () => {
//   // const [project, setProject] = useState('internet-pressure'); // default value for project
//   // const [project] = useState(true);
//   return (
//     <Container>
//       <Hamburger />
//       <h1 style={{ textAlign: "right", padding: "15px" }}>The Melbourne Experiment</h1> 
//       <hr />
//       <NavTitle>Projects</NavTitle>
//       <SideNav defaultSelectedPath='' childrenToggleMode='click' onSelection=''>
//         <Nav id='1'>
//         {/* onNavClick={() => [store.dispatch({type: 'CHANGE_USER', payload: 'AU'}), store.dispatch(changeUserRole('Australia'))]} */}
//           <NavItem icon={faWifi} title={"Internet Pressure"} onClick={() => this.props.project('internet-pressure')} />
//           <SubNavItem>
//           <div dangerouslySetInnerHTML={{ __html: ProjectBlurbs['internet-pressure'] }} />
//               </SubNavItem>
//         </Nav>
//         <Nav id='2'>  
//           <NavItem icon={faBriefcase} title={"Employment Vulnerability"} onClick={() => this.props.project('employment-vulnerability')}  />
//           <SubNavItem>
//           <div dangerouslySetInnerHTML={{ __html: ProjectBlurbs['employment-vulnerability'] }} />
//               </SubNavItem>
//         </Nav>
//       </SideNav>
//     </Container>
//   );
// };


const Container = styled.div`
background: rgba(45, 53, 60, 0.8);
border-radius: 0.25rem;
position: absolute;
z-index: 1;
width: 300px;
height: 85%; // set a proper percentage
color: #a8acb1;
font-family: "Open Sans", sans-serif;
top: 10px;
left: 10px;
`;

  // const Container = styled.div`
//   background: #fff;
//   width: 200px;
//   height: 100%;
//   color: #444;
//   border: 1px solid #e5e5e5;
//   font-family: "Open Sans", sans-serif;
// `;



// const FlexCont = styled.div`
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
//   padding: 8px 12px;
//   background: ${(props) => (props.selected ? "#F5F5F5" : "inherit")};
//   cursor: pointer;
//   transition: all 0.1s ease-in-out;
//   border-left: 4px solid
//     ${(props) => (props.selected ? "#13a4ab" : "transparent")}; // "#DE0A5F"
//   &:hover {
//     background: #f5f5f5;
//   }
// `;

const FlexCont = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
padding: 8px 12px;
color: ${props => (props.selected ? "#FFF" : "inherit")};
cursor: pointer;
transition: all 0.09s ease-in-out;
background: ${props => (props.selected ? "#13a4ab" : "inherit")};
&:hover {
  background: #13a4ab;
  color: #FFF;
}
`;

const IconCont = styled.div`
color: ${(props) => (props.selected ? "#FFF" : "inherit")};
line-height: 16px;
`;

const TextCont = styled.div`
padding-left: 6px;
line-height: 22px;
`;

// trigger state change i.e. state.project = ..
const getSlug = (props) => {
console.log(props.project);
}

const NavItem = (props) => {
const context = React.useContext(NavContext);

return (
  // onClick={addData}
  <FlexCont selected={context.selected}>
    <IconCont selected={context.selected}>
      <FontAwesomeIcon icon={props.icon} />
    </IconCont>
    <TextCont>{props.title}</TextCont>
    {/* <MenuBox selected={context.selected}>
    <div style={{ padding: 4 }}>{props.children}</div>
    </MenuBox> */}
  </FlexCont>
);
};

const NavTitle = styled.div`
padding: 8px;
font-size: 0.92em;
color: #a5a5a5;
`;

// const SubTitle = styled.div`
//   display: flex;
//   padding: 8px 22px;
//   font-size: 0.88em;
//   justify-content: flex-start;
//   align-items: center;
//   color: ${props => (props.selected ? "#FFF" : "inherit")} !important;
// `;

const MenuBox = styled.div`
display: ${props => (props.selected ? "flex" : "none")} !important;
padding: 50px 22px;
font-size: 0.88em;
justify-content: flex-start;
// align-items: center;
color: ${props => (props.selected ? "#FFF" : "inherit")} !important;
background: ${props => (props.selected ? 'rgba(45, 53, 60, 0.8)' : "inherit")}; // "#2d353c"
border-radius: 0.25rem;
// box-shadow: 0 3px 7px rgba(0, 0, 0, 0.133), 0 0.6px 2px rgba(0, 0, 0, 0.1);
position: absolute;
z-index: 1;
height: 100%;
width: 350px;
cursor: pointer;
left: 310px;
top: 0px;
overflow-x: hidden;
transition: 0.09s;
`

const SubNavItem = (props) => {
  const context = React.useContext(NavContext);
  // console.log(context)
  return (
    <MenuBox selected={context.selected}>
      {/* <SubTitleIndicator selected={context.selected} /> */}
      {props.children}
    </MenuBox>
  );
};

// const ProjectDesc = `<p>Lead</p> 
// <p>Prof Carl Grodach</p>
// <br/>
// <p>Overview</p>
// <br/>
// <p>We are developing a digital model of Melbourne that maps the 
//   evolving impacts of COVID-19 on different places and communities
//   of the city. </p>
//   <br/>
//   <p>Explore</p>
//   <br/>
//   <p>We found that the socio-economic diversity between inner and outer
//     Melbourne vastly impacted the effects of COVID-19 on outcomes ..
//   </p>employment
//   <br/>
//   <p>Research</p>
//   <br/>
//   <ul>
//     <li>Grodach, et al. (2020), Title of the work, J. Urban Econ.</li>
//     <li>Grodach, et al. (2020), Title of the work, J. Urban Econ.</li>
//   </ul>`

const HamburgerBox = styled.div`
// color: ${(props) => (props.selected ? "#FFF" : "inherit")};
padding: 10px 10px;
display: flex;
font-size: 2em;
`;

const Hamburger = () => {
const context = React.useContext(NavContext);
return(
  <HamburgerBox>
    {/* {context.selected ? faBars : faBars} */}
  <FontAwesomeIcon style={{cursor: 'pointer'}} icon={faBars} />
  </HamburgerBox>
);
};

const onItemSelection = e => {
  // this.selectProject('employment-vulnerability')}
  console.log('ItemSelected',e);
};

// export default class SideMenu extends Component {

  const selectProject = (id, path, payload) => {
    // change the map
    console.log(id, path, payload)
    // this.props.project(slug);

  }

// render(){
  export const SideMenu = () => {
  // console.log(this.props)
  return (
    <Container>
      <Hamburger />
      <h1 style={{ textAlign: "right", padding: "15px" }}>The Melbourne Experiment</h1> 
      <hr style={{ border: 0, borderTop: "1px solid #E5E5E5" }} />
      <NavTitle>Projects</NavTitle>
      <SideNav defaultSelectedPath='internet-pressure'>
        {/* // onSelect={(selected) => { console.log(selected)}} */}
        <Nav id='internet-pressure'>
        {/* onNavClick={() => [store.dispatch({type: 'CHANGE_USER', payload: 'AU'}), store.dispatch(changeUserRole('Australia'))]} */}
          <NavItem icon={faWifi} title={"Internet Pressure"} />
          <SubNavItem>
          <div dangerouslySetInnerHTML={{ __html: ProjectBlurbs['internet-pressure'] }} />
              </SubNavItem>
        </Nav>
        <Nav id='employment-vulnerability'>  
          <NavItem icon={faBriefcase} title={"Employment Vulnerability"}  />
          <SubNavItem>
          <div dangerouslySetInnerHTML={{ __html: ProjectBlurbs['employment-vulnerability'] }} />
              </SubNavItem>
        </Nav>
      </SideNav>
    </Container>
  );


}
