import { App } from "@carto/toolkit";
import React from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import Home from "../../app";
import About from "../pages/About";

export default function Header() {
  return (
    <header style={headerStyle}>
      <h1>The Melbourne Experiment</h1>
      <Link style={linkStyle} to='/'>
        Home
      </Link>{" "}
      |{" "}
      <Link style={linkStyle} to='/about'>
        About
      </Link>
    </header>
  );
}
const headerStyle = {
  background: "#333",
  color: "#fff",
  textAlign: "center",
  padding: "10px",
};
const linkStyle = {
  color: "#fff",
  textDecoration: "none",
};
